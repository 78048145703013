<template>
  <div class="pie-chart">
    <vuestic-chart
      :data="chartData"
      type="pie"
      :options="{
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                display: false
              }
            }
          ]
        }
      }"
      class="chart"
    ></vuestic-chart>
  </div>
</template>

<script>
import VuesticChart from '../../vuestic-theme/vuestic-components/vuestic-chart/VuesticChart.vue'

export default {
  components: {
    VuesticChart
  },
  name: 'PieChart',
  props: {
    labels: {
      type: Array,
      required: true
    },
    datasets: {
      type: Array,
      required: true
    },
    config: {
      type: Object,
      required: false
    }
  },
  computed: {
    chartData() {
      let palette = this.$store.getters.palette
      return {
        labels: this.labels,
        datasets: [
          {
            backgroundColor: [
              palette.primary,
              '#f47a00',
              palette.success,
              palette.danger,
              palette.warning,
              '#55aa11',
              palette.info,
              '#ffe314',
              '#f40000'
            ],
            data: this.datasets
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pie-chart {
  width: 100%;
  .chart {
    width: 100%;
    height: 240px;
  }
}
</style>
