<template>
  <span class="d-flex justify-content-center align-items-center">
    <span
      class="status-icon"
      :class="rowData.status"
      v-if="rowData.status === 'created'"
    >
      <i class="fa fa-plus" aria-hidden="true"></i>
    </span>
    <span
      class="status-icon"
      :class="rowData.status"
      v-else-if="rowData.status === 'schedule-customers'"
    >
      <i class="fa fa-calendar" aria-hidden="true"></i>
    </span>
    <span
      class="rotating status-icon"
      :class="rowData.status"
      v-else-if="rowData.status === 'verifying-customers'"
    >
      <i class="fa fa-refresh" aria-hidden="true"></i>
    </span>
    <span
      class="status-icon"
      :class="rowData.status"
      v-else-if="rowData.status === 'verification-failed'"
    >
      <i class="fa fa-times" aria-hidden="true"></i>
    </span>
    <span
      class="status-icon"
      :class="rowData.status"
      v-else-if="rowData.status === 'cancelled'"
    >
      <i class="fa fa-times" aria-hidden="true"></i>
    </span>
    <span
      class="status-icon"
      :class="rowData.status"
      v-else-if="rowData.status === 'corrupted-ignored'"
    >
      <i class="fa fa-trash" aria-hidden="true"></i>
    </span>
    <span
      class="status-icon"
      :class="rowData.status"
      v-else-if="rowData.status === 'customers-inserted'"
    >
      <i class="fa fa-user" aria-hidden="true"></i>
    </span>
    <span
      class="status-icon"
      :class="rowData.status"
      v-else-if="rowData.status === 'in-progress'"
    >
      <i class="fa fa-refresh" aria-hidden="true"></i>
    </span>
    <span
      class="rotating status-icon"
      :class="rowData.status"
      v-else-if="rowData.status === 'active'"
    >
      <i class="fa fa-refresh" aria-hidden="true"></i>
    </span>
    <span
      class="status-icon"
      :class="rowData.status"
      v-else-if="rowData.status === 'paused'"
    >
      <i class="fa fa-pause" aria-hidden="true"></i>
    </span>
    <span
      class="status-icon"
      :class="rowData.status"
      v-else-if="rowData.status === 'finished'"
    >
      <i class="fa-stop fa" aria-hidden="true"></i>
    </span>

    <span
      class="status-icon"
      :class="rowData.status"
      v-else-if="rowData.status === 'completed'"
    >
      <i class="fa fa-check" aria-hidden="true"></i>
    </span>
    <span class="status-icon" :class="rowData.status" v-else>
      <i class="fa fa-question" aria-hidden="true"></i>
    </span>

    <span class="mx-1 capitalize status-text" :class="rowData.status">
      {{ $t(`auto-dialer.list.table.statuses.${rowData.status}`) }}
    </span>
  </span>
</template>

<script>
export default {
  props: {
    rowData: {
      type: Object,
      required: true
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.status-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;

  i {
    font-size: 16px;
  }
}
.status-text,
.status-icon {
  &.created {
    color: #26c39c;
  }
  &.schedule-customers {
    color: #9000ff;
  }
  &.verifying-customers {
    color: #25b932;
  }
  &.verification-failed {
    color: #ef3636;
  }
  &.cancelled {
    color: #ef6a36;
  }
  &.corrupted-ignored {
    color: #ec952a;
  }
  &.customers-inserted {
    color: #4c00ff;
  }
  &.in-progress,
  &.active {
    color: #25cdd3;
  }
  &.paused {
    color: #ec952a;
  }
  &.finished {
    color: #ef3636;
  }
  &.completed {
    color: #007bff;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotating {
  animation: spin 1s linear infinite;
}
</style>
