<template>
  <div :dir="dir">
    <div class="mb-4 row">
      <div class="col-12 col-lg-8">
        <PieChart
          :config="agentsChart.config"
          :labels="agentsChart.labels"
          :datasets="agentsChart.datasets"
        />
        <div class="mt-4 agents-metrics row">
          <div class="p-2 col-3">
            <div class="metric-card">
              <h6>
                {{
                  $t('auto-dialer.campaign-metrics.summary.chart.totalAgents')
                }}
              </h6>
              <p>{{ liveMetrics.totalAgents }}</p>
            </div>
          </div>
          <div class="p-2 col-3">
            <div class="metric-card primary">
              <h6>
                {{
                  $t('auto-dialer.campaign-metrics.summary.chart.onlineAgents')
                }}
              </h6>
              <p>{{ liveMetrics.onlineAgents }}</p>
            </div>
          </div>
          <div class="p-2 col-3">
            <div class="metric-card orange">
              <h6>
                {{
                  $t(
                    'auto-dialer.campaign-metrics.summary.chart.availableAgents'
                  )
                }}
              </h6>
              <p>{{ liveMetrics.availableAgents }}</p>
            </div>
          </div>
          <div class="p-2 col-3">
            <div class="metric-card success">
              <h6>
                {{
                  $t('auto-dialer.campaign-metrics.summary.chart.onCallAgents')
                }}
              </h6>
              <p>{{ liveMetrics.onCallAgents }}</p>
            </div>
          </div>
          <div class="p-2 col-3">
            <div class="danger metric-card">
              <h6>
                {{
                  $t('auto-dialer.campaign-metrics.summary.chart.dialingAgents')
                }}
              </h6>
              <p>{{ liveMetrics.dialingAgents }}</p>
            </div>
          </div>
          <div class="p-2 col-3">
            <div class="metric-card warning">
              <h6>
                {{
                  $t('auto-dialer.campaign-metrics.summary.chart.onBreakAgents')
                }}
              </h6>
              <p>{{ liveMetrics.onBreakAgents }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="calls-metrics">
          <div class="metric-card primary">
            <h6>
              {{
                $t(
                  'auto-dialer.campaign-metrics.summary.call-metrics.callsWaiting'
                )
              }}
            </h6>
            <p>{{ liveMetrics.callsWaiting }}</p>
          </div>
          <div class="metric-card success">
            <h6>
              {{
                $t(
                  'auto-dialer.campaign-metrics.summary.call-metrics.callsInProgress'
                )
              }}
            </h6>
            <p>{{ liveMetrics.callsInProgress }}</p>
          </div>
          <div class="info metric-card">
            <h6>
              {{
                $t(
                  'auto-dialer.campaign-metrics.summary.call-metrics.oldestCallWaitTime'
                )
              }}
            </h6>
            <p>
              {{ Math.round(liveMetrics.oldestCallWaitTime / 1000) }}
              {{ $t('auto-dialer.campaign-metrics.shared.seconds') }}
            </p>
          </div>
          <div class="metric-card warning">
            <h6>
              {{
                $t(
                  'auto-dialer.campaign-metrics.summary.call-metrics.averageCallWaitTime'
                )
              }}
            </h6>
            <p>
              {{ Math.round(liveMetrics.averageCurrentWaitTime / 1000) }}
              {{ $t('auto-dialer.campaign-metrics.shared.seconds') }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <h5 class="mt-0">
      {{ $t('auto-dialer.campaign-metrics.tables.waiting-calls.title') }}
    </h5>
    <data-table
      class="table"
      ref="didsTable"
      :data="liveMetrics.waitingCalls"
      :fields="translatedWaitingCallsTableFields"
      :isLoading="loading"
      :pagination="pagination"
    >
      <template slot="waitTime" slot-scope="props">
        {{ Math.round(props.rowData.waitTime / 1000) }}
        {{ $t('auto-dialer.campaign-metrics.shared.seconds') }}
      </template>
    </data-table>

    <h5 class="mt-0">
      {{ $t('auto-dialer.campaign-metrics.tables.in-progress-calls.title') }}
    </h5>
    <data-table
      class="table"
      ref="didsTable"
      :data="liveMetrics.inProgressCalls"
      :fields="translatedInProgressCallsTableFields"
      :isLoading="loading"
      :pagination="pagination"
    >
      <template slot="actions" slot-scope="props">
        <b-button
          class="px-3 py-2"
          @click="spy(props.rowData)"
          v-if="
            extensionStatus === 'connected' &&
              props.rowData.agentId !== currentExtension
          "
        >
          {{
            $t(
              'auto-dialer.campaign-metrics.tables.in-progress-calls.actions.listen'
            )
          }}
        </b-button>
      </template>
    </data-table>

    <div class="d-flex justify-content-between align-items-center head">
      <h5 class="mt-0">
        {{ $t('auto-dialer.campaign-metrics.tables.agent-details.title') }}
      </h5>
      <items-per-page
        :options="perPageOptions"
        :label="$t('extensions.per-page')"
        :defaultPerPage="pagination.perPage"
        @items-per-page="onPerPageChange"
      />
    </div>
    <data-table
      class="table"
      ref="agentDetailsTable"
      :data="liveMetrics.agentDetails"
      :fields="translatedAgentDetailsTableFields"
      :isLoading="loading"
      :pagination="pagination"
    >
    </data-table>
  </div>
</template>

<script>
import PieChart from '../../../elements/charts/PieChart.vue'
import DataTable from '../../../elements/DataTable.vue'
import { mapGetters } from 'vuex'
import AutoDialerService from '../../../services/autoDialer.service'
import moment from 'moment-timezone'
import ItemsPerPage from '../../../vuestic-theme/vuestic-components/vuestic-datatable/datatable-components/ItemsPerPage'

export default {
  name: 'campaign-metrics',
  components: {
    PieChart,
    DataTable,
    ItemsPerPage
  },
  props: {
    metrics: Object,
    campaignId: String
  },
  data() {
    return {
      agentsChart: {
        labels: [
          this.$t('auto-dialer.campaign-metrics.summary.chart.onlineAgents'),
          this.$t('auto-dialer.campaign-metrics.summary.chart.availableAgents'),
          this.$t('auto-dialer.campaign-metrics.summary.chart.onCallAgents'),
          this.$t('auto-dialer.campaign-metrics.summary.chart.dialingAgents'),
          this.$t('auto-dialer.campaign-metrics.summary.chart.onBreakAgents')
        ],
        datasets: [
          this.metrics.onlineAgents,
          this.metrics.availableAgents,
          this.metrics.onCallAgents,
          this.metrics.dialingAgents,
          this.metrics.onBreakAgents
        ]
      },
      liveMetrics: null,
      pagination: {
        perPage: 10
      },
      loading: false,
      interval: null,
      perPageOptions: [
        {
          value: 10
        },
        {
          value: 25
        },
        {
          value: 100
        }
      ]
    }
  },
  filters: {
    formatTime(time) {
      return time ? moment(time).format('YYYY-MM-DD HH:mm a') : ''
    }
  },
  computed: {
    ...mapGetters(['extensionStatus']),
    currentExtension() {
      return this.$root.$refs.phone
        ? this.$root.$refs.phone.extension.ext
        : null
    },
    translatedWaitingCallsTableFields() {
      return [
        {
          name: '__slot:waitTime',
          title: this.$t(
            'auto-dialer.campaign-metrics.tables.waiting-calls.columns.waitTime'
          )
        },
        {
          name: 'callerInfo.callerNumber',
          title: this.$t(
            'auto-dialer.campaign-metrics.tables.waiting-calls.columns.callerNumber'
          )
        },
        {
          name: 'callerInfo.callerName',
          title: this.$t(
            'auto-dialer.campaign-metrics.tables.waiting-calls.columns.callerName'
          )
        }
      ]
    },
    translatedInProgressCallsTableFields() {
      return [
        {
          name: 'agentId',
          title: this.$t(
            'auto-dialer.campaign-metrics.tables.in-progress-calls.columns.agentExtension'
          )
        },
        {
          name: 'callerInfo.callerNumber',
          title: this.$t(
            'auto-dialer.campaign-metrics.tables.in-progress-calls.columns.callerNumber'
          )
        },
        {
          name: 'callerInfo.callerName',
          title: this.$t(
            'auto-dialer.campaign-metrics.tables.in-progress-calls.columns.callerName'
          )
        },
        {
          name: '__slot:actions',
          title: this.$t(
            'auto-dialer.campaign-metrics.tables.in-progress-calls.columns.actions'
          )
        }
      ]
    },
    translatedAgentDetailsTableFields() {
      return [
        {
          name: 'agentId',
          title: this.$t(
            'auto-dialer.campaign-metrics.tables.agent-details.columns.agentExtension'
          )
        },
        {
          name: 'status',
          title: this.$t(
            'auto-dialer.campaign-metrics.tables.agent-details.columns.status'
          )
        }
      ]
    },
    dir() {
      const locale = this.$i18n.locale()
      if (locale === 'ar') return 'rtl'
      else return 'ltr'
    }
  },
  methods: {
    spy(rowData) {
      const phone = this.$root.$refs.phone
      if (!phone) return

      phone.spy(rowData.agentId)
    },
    onPerPageChange(perPage) {
      this.pagination.perPage = perPage
      this.$refs.agentDetailsTable.onPaginationData(this.pagination)
    }
  },
  mounted() {
    this.liveMetrics = this.metrics

    this.interval = setInterval(async () => {
      try {
        const metrics = await AutoDialerService.getCampaignMetrics(
          this.campaignId
        )
        this.liveMetrics = metrics
        this.agentsChart.datasets = [
          metrics.onlineAgents,
          metrics.availableAgents,
          metrics.onCallAgents,
          metrics.dialingAgents,
          metrics.onBreakAgents
        ]
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message
        this.toast(message, {
          type: 'error'
        })
      }
    }, 10000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style lang="scss" scoped>
.calls-metrics {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.metric-card {
  padding: 10px;
  border-radius: 5px;
  border-width: 2px 2px 2px 8px;
  border-style: solid;
  border-color: hsl(199, 0%, 41%);
  background-color: hsla(199, 0%, 41%, 0.1);

  h6,
  p {
    margin: 0;
  }
  &.primary {
    border-color: hsl(199, 100%, 41%);
    background-color: hsla(199, 100%, 41%, 0.1);
  }
  &.success {
    border-color: hsl(153, 68%, 42%);
    background-color: hsla(153, 68%, 42%, 0.1);
  }
  &.info {
    border-color: hsl(207, 82%, 28%);
    background-color: hsla(207, 82%, 28%, 0.1);
  }
  &.danger {
    border-color: hsl(348, 97%, 63%);
    background-color: hsla(348, 97%, 63%, 0.1);
  }
  &.orange {
    border-color: hsl(30, 100%, 48%);
    background-color: hsla(30, 100%, 48%, 0.1);
  }
  &.warning {
    border-color: hsl(44, 100%, 67%);
    background-color: hsla(44, 100%, 67%, 0.1);
  }
}
.table {
  min-height: auto;
}
</style>
