var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pie-chart"},[_c('vuestic-chart',{staticClass:"chart",attrs:{"data":_vm.chartData,"type":"pie","options":{
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false
            },
            ticks: {
              display: false
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              display: false
            },
            ticks: {
              display: false
            }
          }
        ]
      }
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }