<template>
  <div class="actions">
    <b-button
      class="px-3"
      variant="primary"
      size="sm"
      @click="startCampaign"
      :disabled="isStarting"
      v-if="
        rowData.isDraft &&
          ['customers-inserted', 'corrupted-ignored'].includes(rowData.status)
      "
    >
      <span class="d-flex align-items-center">
        <half-circle-spinner
          :animation-duration="1500"
          :size="20"
          v-if="isStarting"
        />
        <i class="fa fa-play" aria-hidden="true" v-else></i>
        <span class="mx-1">
          {{
            isStarting
              ? $t('auto-dialer.list.table.actionsButtons.starting')
              : $t('auto-dialer.list.table.actionsButtons.start-now')
          }}
        </span>
      </span>
    </b-button>

    <b-button
      class="px-3"
      variant="outline-primary"
      size="sm"
      @click="pauseCampaign"
      :disabled="isPausing"
      v-if="['in-progress', 'active'].includes(rowData.status)"
    >
      <span class="d-flex align-items-center">
        <half-circle-spinner
          :animation-duration="1500"
          :size="20"
          color="#008ecf"
          v-if="isPausing"
        />
        <i class="fa fa-pause" aria-hidden="true" v-else></i>
        <span class="mx-1">
          {{
            isPausing
              ? $t('auto-dialer.list.table.actionsButtons.pausing')
              : $t('auto-dialer.list.table.actionsButtons.pause')
          }}
        </span>
      </span>
    </b-button>
    <b-button
      class="px-3"
      variant="primary"
      size="sm"
      @click="resumeCampaign"
      :disabled="isResuming"
      v-if="rowData.status === 'paused'"
    >
      <span class="d-flex align-items-center">
        <half-circle-spinner
          :animation-duration="1500"
          :size="20"
          v-if="isResuming"
        />
        <i class="fa fa-play" aria-hidden="true" v-else></i>
        <span class="mx-1">
          {{
            isResuming
              ? $t('auto-dialer.list.table.actionsButtons.resuming')
              : $t('auto-dialer.list.table.actionsButtons.resume')
          }}
        </span>
      </span>
    </b-button>

    <b-button
      class="px-3"
      variant="outline-danger"
      size="sm"
      @click="finishCampaign"
      :disabled="isFinishing"
      v-if="['in-progress', 'active', 'paused'].includes(rowData.status)"
    >
      <span class="d-flex align-items-center">
        <half-circle-spinner
          :animation-duration="1500"
          :size="20"
          color="#008ecf"
          v-if="isFinishing"
        />
        <i class="fa-stop fa" aria-hidden="true" v-else></i>
        <span class="mx-1">
          {{
            isFinishing
              ? $t('auto-dialer.list.table.actionsButtons.finishing')
              : $t('auto-dialer.list.table.actionsButtons.finish')
          }}
        </span>
      </span>
    </b-button>

    <b-button
      class="px-3"
      variant="info"
      size="sm"
      @click="downloadReport"
      :disabled="isDownloadingReport"
      v-if="['finished', 'completed'].includes(rowData.status)"
    >
      <span class="d-flex align-items-center">
        <half-circle-spinner
          :animation-duration="1500"
          :size="20"
          v-if="isDownloadingReport"
        />
        <i class="fa fa-download" aria-hidden="true" v-else></i>
        <span class="mx-1">
          {{
            isDownloadingReport
              ? $t('auto-dialer.list.table.actionsButtons.downloading')
              : $t('auto-dialer.list.table.actionsButtons.report')
          }}
        </span>
      </span>
    </b-button>

    <router-link
      :to="`/auto-dialer/${rowData.id}/update`"
      v-if="rowData.isDraft"
    >
      <b-button variant="warning" size="sm" class="px-3">
        <i class="fa fa-pencil" aria-hidden="true"></i>
      </b-button>
    </router-link>
    <router-link :to="`/auto-dialer/${rowData.id}/campaign-details`">
      <b-button variant="info" size="sm" class="px-3">
        <i class="fa fa-eye" aria-hidden="true"></i>
      </b-button>
    </router-link>
    <router-link
      :to="`/auto-dialer/${rowData.id}/attach-customers`"
      v-if="['created'].includes(rowData.status)"
    >
      <b-button variant="warning" size="sm" class="px-3">
        {{ $t('auto-dialer.list.table.actionsButtons.add-customers') }}
      </b-button>
    </router-link>

    <router-link
      :to="`/auto-dialer/${rowData.id}/corrupted-rows`"
      v-if="rowData.status === 'verification-failed'"
    >
      <b-button variant="warning" size="sm" class="px-3">
        {{ $t('auto-dialer.list.table.actionsButtons.corrupted-rows') }}
      </b-button>
    </router-link>

    <b-button
      variant="info"
      size="sm"
      class="px-3"
      @click="showMetrics"
      v-if="['active'].includes(rowData.status)"
      :disabled="isFetchingMetrics"
    >
      <i
        class="fa fa-bar-chart"
        aria-hidden="true"
        v-if="!isFetchingMetrics"
      ></i>
      <half-circle-spinner
        :animation-duration="1500"
        :size="20"
        v-if="isFetchingMetrics"
      />
    </b-button>

    <router-link
      :to="`/auto-dialer/${rowData.id}/cdrs`"
      v-if="
        ['completed', 'finished', 'paused', 'active', 'in-progress'].includes(
          rowData.status
        )
      "
    >
      <b-button variant="info" size="sm" class="px-3">
        <i class="fa fa-list" aria-hidden="true"></i>
        <span class="mx-1">
          {{ $t('auto-dialer.list.table.actionsButtons.cdrs') }}
        </span>
      </b-button>
    </router-link>

    <!-- <b-button class="px-3" variant="danger" size="sm" @click="deleteCampaign">
      <i class="fa fa-trash" aria-hidden="true"></i>
    </b-button> -->
  </div>
</template>

<script>
import AutoDialerService from '../../../../services/autoDialer.service'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'
import Swal from 'sweetalert2'

export default {
  components: {
    HalfCircleSpinner
  },
  props: {
    rowData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isDeleting: false,
      isStarting: false,
      isPausing: false,
      isResuming: false,
      isFinishing: false,
      isDownloadingReport: false,
      isFetchingMetrics: false
    }
  },
  computed: {
    step() {
      switch (this.rowData.status) {
        case 'customers-inserted':
          return 'assign-agents'

        default:
          return 'attach-customers'
      }
    }
  },
  methods: {
    async startCampaign() {
      try {
        Swal.fire({
          title: this.$t('auto-dialer.list.modals.start.title'),
          html: this.$t('auto-dialer.list.modals.start.html'),
          showCancelButton: true,
          confirmButtonText: this.$t(
            'auto-dialer.list.modals.start.confirmButtonText'
          ),
          cancelButtonText: this.$t(
            'auto-dialer.list.modals.start.cancelButtonText'
          ),
          customClass: {
            confirmButton: 'btn btn-primary btn-sm',
            cancelButton: ' btn btn-sm mx-2'
          },
          buttonsStyling: false
        }).then(async res => {
          if (res.value) {
            try {
              this.isStarting = true
              await AutoDialerService.startCampaign(this.rowData.id)
              this.toast(this.$t('auto-dialer.list.toasts.campaign-started'))
              this.$emit('refetch')
            } catch (error) {
              const message = error.response
                ? error.response.data.message
                : error.message
              this.toast(message, {
                type: 'error'
              })
            } finally {
              this.isStarting = false
            }
          }
        })
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message
        this.toast(message, {
          type: 'error'
        })
      }
    },
    async finishCampaign() {
      try {
        Swal.fire({
          title: this.$t('auto-dialer.list.modals.finish.title'),
          html: this.$t('auto-dialer.list.modals.finish.html'),
          showCancelButton: true,
          confirmButtonText: this.$t(
            'auto-dialer.list.modals.finish.confirmButtonText'
          ),
          cancelButtonText: this.$t(
            'auto-dialer.list.modals.finish.cancelButtonText'
          ),
          customClass: {
            confirmButton: 'btn btn-primary btn-sm',
            cancelButton: ' btn btn-sm mx-2'
          },
          buttonsStyling: false
        }).then(async res => {
          if (res.value) {
            try {
              this.isFinishing = true
              await AutoDialerService.finishCampaign(this.rowData.id)
              this.toast(this.$t('auto-dialer.list.toasts.campaign-finished'))
              this.$emit('refetch')
            } catch (error) {
              const errorMessage = error.response
                ? error.response.data.message
                : error.message

              this.toast(errorMessage, {
                type: 'error'
              })
            } finally {
              this.isFinishing = false
            }
          }
        })
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message
        this.toast(message, {
          type: 'error'
        })
      }
    },
    async pauseCampaign() {
      try {
        Swal.fire({
          title: this.$t('auto-dialer.list.modals.pause.title'),
          html: this.$t('auto-dialer.list.modals.pause.html'),
          showCancelButton: true,
          confirmButtonText: this.$t(
            'auto-dialer.list.modals.pause.confirmButtonText'
          ),
          cancelButtonText: this.$t(
            'auto-dialer.list.modals.pause.cancelButtonText'
          ),
          customClass: {
            confirmButton: 'btn btn-primary btn-sm',
            cancelButton: ' btn btn-sm mx-2'
          },
          buttonsStyling: false
        }).then(async res => {
          if (res.value) {
            try {
              this.isPausing = true
              await AutoDialerService.pauseCampaign(this.rowData.id)
              this.toast(this.$t('auto-dialer.list.toasts.campaign-paused'))
              this.$emit('refetch')
            } catch (error) {
              const errorMessage = error.response
                ? error.response.data.message
                : error.message

              this.toast(errorMessage, {
                type: 'error'
              })
            } finally {
              this.isPausing = false
            }
          }
        })
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message
        this.toast(message, {
          type: 'error'
        })
      }
    },
    async resumeCampaign() {
      try {
        Swal.fire({
          title: this.$t('auto-dialer.list.modals.resume.title'),
          html: this.$t('auto-dialer.list.modals.resume.html'),
          showCancelButton: true,
          confirmButtonText: this.$t(
            'auto-dialer.list.modals.resume.confirmButtonText'
          ),
          cancelButtonText: this.$t(
            'auto-dialer.list.modals.resume.cancelButtonText'
          ),
          customClass: {
            confirmButton: 'btn btn-primary btn-sm',
            cancelButton: ' btn btn-sm mx-2'
          },
          buttonsStyling: false
        }).then(async res => {
          if (res.value) {
            try {
              this.isResuming = true
              await AutoDialerService.resumeCampaign(this.rowData.id)
              this.toast(this.$t('auto-dialer.list.toasts.campaign-resumed'))
              this.$emit('refetch')
            } catch (error) {
              const errorMessage = error.response
                ? error.response.data.message
                : error.message

              this.toast(errorMessage, {
                type: 'error'
              })
            } finally {
              this.isResuming = false
            }
          }
        })
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message
        this.toast(message, {
          type: 'error'
        })
      }
    },
    async downloadReport() {
      try {
        this.isDownloadingReport = true
        const res = await AutoDialerService.downloadReport(this.rowData.id)
        const blob = new Blob([res], {
          type: 'text/csv'
        })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `${this.rowData.name}-report.csv`
        a.click()
        window.URL.revokeObjectURL(url)

        this.toast(this.$t('auto-dialer.list.toasts.report-downloaded'))
        this.$emit('refetch')
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message
        this.toast(message, {
          type: 'error'
        })
      } finally {
        this.isDownloadingReport = false
      }
    },
    async showMetrics() {
      try {
        this.isFetchingMetrics = true
        const res = await AutoDialerService.getCampaignMetrics(this.rowData.id)
        this.$emit('showMetrics', res)
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message
        this.toast(message, {
          type: 'error'
        })
      } finally {
        this.isFetchingMetrics = false
      }
    },
    async deleteCampaign() {
      try {
        this.isDeleting = true
        await AutoDialerService.deleteCampaign(this.rowData.id)
        this.toast('campaign deleted successfully')
        this.$emit('refetch')
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message
        this.toast(message, {
          type: 'error'
        })
      } finally {
        this.isDeleting = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
</style>
